import {
  IClientTarget,
  ISiteTarget,
  isSiteTarget,
  isTenantTarget,
  ITenantTarget,
} from "wombat-global/src/typings";

export const ROUTES = import.meta.glob("../pages/**/[a-zA-Z0-9]*.tsx");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PRESERVED = import.meta.glob<any>("../pages/(_app|404).tsx", {
  eager: true,
});

export const routeMap = Object.keys(ROUTES).reduce(
  (routes, key) => {
    if (key.includes("_")) {
      return routes;
    }
    routes[key] = key
      .replace(/\.\.\/pages|index|\.tsx$/g, "")
      .replace(/\[\.{3}.+\]/, "*")
      .replace(/\[(.[^\]]+)\]/g, ":$1")
      .replace(/(.+)\/$/g, "$1")
      .replace(/^\/(.+)/g, "$1");

    return routes;
  },
  {} as Record<keyof typeof ROUTES, string>,
);

export const regularRoutes = Object.values(routeMap);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const preservedRoutes: Record<string, any> = Object.keys(
  PRESERVED,
).reduce((routes, key) => {
  const path = key.replace(/\.\.\/pages\/|\.tsx$/g, "");
  // todo add path mapping for no ending / and / (training slash)
  return { ...routes, [path]: PRESERVED[key]?.default };
}, {});

export function webLinkPath(
  target: IClientTarget | ISiteTarget | ITenantTarget,
): string {
  if (isSiteTarget(target)) {
    return `/site/${target.siteId}`;
  }
  const { tenantId, divisionId } = target;
  if (isTenantTarget(target)) {
    if (tenantId && divisionId) {
      return `/t/${tenantId}/d/${divisionId}`;
    }
    return `/t/${tenantId}`;
  }

  const { orgId } = target;
  if (divisionId && tenantId) {
    return `/o/${orgId}/t/${tenantId}/d/${divisionId}`;
  }
  if (tenantId) {
    return `/o/${orgId}/t/${tenantId}`;
  }
  return `/o/${orgId}`;
}

export function staffLinkPath(
  target: IClientTarget | ISiteTarget | ITenantTarget,
): string {
  if (isSiteTarget(target)) {
    return `/site/${target.siteId}`;
  }
  const { tenantId, divisionId } = target;
  if (isTenantTarget(target)) {
    if (tenantId && divisionId) {
      return `/tenant/${tenantId}/division/${divisionId}`;
    }
    return `/tenant/${tenantId}`;
  }

  const { orgId } = target;
  if (divisionId && tenantId) {
    return `/organization/${orgId}/tenant/${tenantId}/division/${divisionId}`;
  }
  if (tenantId) {
    return `/organization/${orgId}/tenant/${tenantId}`;
  }
  return `/organization/${orgId}`;
}
