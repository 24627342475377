// libraries
import {
  indexedDBLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  getStorage,
  connectStorageEmulator,
  FirebaseStorage,
} from "firebase/storage";
import {
  CACHE_SIZE_UNLIMITED,
  connectFirestoreEmulator,
  Firestore,
  FirestoreSettings,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";

import { getProjectInfraSettings } from "./_projectInfraConstants";

// types & constants
import { WombatRegions } from "wombat-global/src/typings/multi-region";

const env = import.meta.env;

const allowProxying =
  env.REACT_APP_ALLOW_PROXY && env.REACT_APP_ALLOW_PROXY === "true";
const usingProxy = allowProxying && window.location.port === "8000";
let authDomain = env.REACT_APP_AUTH_DOMAIN;
let databaseUrl = env.REACT_APP_FIRESTORE_URL;
let storageUrl = env.REACT_APP_STORAGE_BUCKET;
if (usingProxy) {
  authDomain = window.location.origin; // example "http://localhost:9199"
  databaseUrl = window.location.hostname;
  storageUrl = window.location.hostname;
}

const config: FirebaseOptions = {
  apiKey: env.REACT_APP_API_KEY,
  appId: env.REACT_APP_APP_ID,
  authDomain: authDomain,
  databaseURL: databaseUrl,
  measurementId: env.REACT_APP_MEASUREMENT_ID,
  projectId: env.REACT_APP_PROJECT_ID,
  storageBucket: env.REACT_APP_STORAGE_BUCKET,
};
if (env.REACT_APP_AUTH_DOMAIN?.includes("localhost") || usingProxy) {
  delete config.authDomain;
}

export const _firebase = initializeApp(config);

/**
 * auth setup
 */
export const auth = getAuth(_firebase);

if (usingProxy) {
  connectAuthEmulator(auth, window.location.origin, {
    disableWarnings: true,
  });
} else if (env.REACT_APP_AUTH_DOMAIN?.includes("localhost")) {
  connectAuthEmulator(auth, env.REACT_APP_AUTH_DOMAIN, {
    disableWarnings: true,
  });
}
setPersistence(auth, indexedDBLocalPersistence);

/**
 * firestore setup
 */
const _firestoreConfig: FirestoreSettings = {
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  }),
};

const regionProjectId = env.REACT_APP_FIRESTORE_URL?.includes("localhost")
  ? "local"
  : env.REACT_APP_PROJECT_ID;

export const firestoreRegionDb: Record<WombatRegions, Firestore> = {
  us: initializeFirestore(
    _firebase,
    _firestoreConfig,
    getProjectInfraSettings(regionProjectId).us.firestoreId,
  ),
  ca: initializeFirestore(
    _firebase,
    _firestoreConfig,
    getProjectInfraSettings(regionProjectId).ca.firestoreId,
  ),
};
export const firestore = firestoreRegionDb.us;

if (usingProxy) {
  connectFirestoreEmulator(firestore, window.location.hostname, Number(8000));
} else if (env.REACT_APP_FIRESTORE_URL?.includes("localhost")) {
  const [, port = 8080] = env.REACT_APP_FIRESTORE_URL.split(":");
  connectFirestoreEmulator(firestore, "127.0.0.1", Number(port));
}

/**
 * storage setup
 */
export const firebaseRegionStorage: Record<WombatRegions, FirebaseStorage> = {
  us: getStorage(
    _firebase,
    getProjectInfraSettings(regionProjectId).us.bucketName,
  ),
  ca: getStorage(
    _firebase,
    getProjectInfraSettings(regionProjectId).ca.bucketName,
  ),
};
export const storage = getStorage(_firebase, env.REACT_APP_STORAGE_BUCKET);
if (usingProxy) {
  // Using the caddy proxy, connect through the proxy port
  connectStorageEmulator(
    firebaseRegionStorage.us,
    window.location.hostname,
    8000,
  );
  connectStorageEmulator(
    firebaseRegionStorage.ca,
    window.location.hostname,
    8000,
  );
} else if (env.REACT_APP_STORAGE_URL?.includes("localhost")) {
  const [host = "localhost", port = 9199] = env.REACT_APP_STORAGE_URL.split(":"); // prettier-ignore
  connectStorageEmulator(firebaseRegionStorage.us, host, Number(port));
  connectStorageEmulator(firebaseRegionStorage.ca, host, Number(port));
}
