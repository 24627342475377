// libraries
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { signInWithEmailAndPassword, User } from "firebase/auth";

// services
import request from "../../services/request.service";
import { auth } from "../../services/firebase.service";

// contexts
import { useDataStore } from "../dataStore.context";

// utilities
// import { hasPermission } from "wombat-global/src/utilities/permissions";

// types
import { TDecodedToken } from "./authentication.types";
import { READ } from "wombat-global/src/permission.maps";

type AuthenticationContextType = {
  isAuthenticated: boolean;
  hasPermission: (permissionFlag: number) => boolean;
  logout: () => void;
  reload: () => void;
  login: (
    email: string,
    password: string,
  ) => Promise<void | [User, TDecodedToken]>;
  initialized: boolean;
  user: User | null;
  uid?: string;
  authToken: TDecodedToken | null;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  isAuthenticated: false,
  hasPermission: () => false,
  logout: async () => null,
  login: async () => {
    return;
  },
  reload: async () => null,
  initialized: false,
  user: null,
  uid: undefined,
  authToken: null,
});

export const AuthenticationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { cleanStoreData } = useDataStore();
  const [authToken, setAuthToken] = useState<TDecodedToken | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setAuthentication] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [tokenExpiresAt, setTokenExpiresAt] = useState<number>( new Date().getTime() + 3600 * 1000); // prettier-ignore

  /**
   * @private
   *
   * This should be used within the login, onAuth, & reload calls and must be Idempotent
   */
  const setUserAuthentication = useCallback(
    (_user: User, token: TDecodedToken) => {
      token && request.setAccessToken(token.token);

      setTokenExpiresAt(token ? new Date(token.expirationTime).getTime() : -1);
      setAuthToken(token);
      setUser(_user);
      setAuthentication(true);
    },
    [],
  );

  /**
   * @public
   *
   * useHasPermission checks if the current user has specified permission (encoded in permission bit flag)
   */
  const userHasPermission = useCallback(
    (permissionFlag: number, token?: TDecodedToken | null) => {
      const _token = token || authToken;
      if (!_token?.claims) {
        return false;
      }

      return (
        _token.claims.perm._p !== undefined &&
        (_token.claims.perm._p & permissionFlag) == permissionFlag
      );
    },
    [authToken],
  );

  /**
   * @public
   *
   * reload will reload the user auth token with the new claim (use when user claim is updated in the BE)
   */
  const reload = useCallback(async () => {
    if (auth.currentUser) {
      const token = (await auth.currentUser.getIdTokenResult(
        true,
      )) as TDecodedToken;
      setUserAuthentication(auth.currentUser, token);
    }
  }, [setUserAuthentication]);

  /**
   * @public
   *
   * logout
   */
  const logout = useCallback(async () => {
    await auth.signOut();
    request.setAccessToken(undefined);
    setAuthentication(false);
    setTokenExpiresAt(-1);
    setAuthToken(null);
    setUser(null);
    cleanStoreData();
    return;
  }, [cleanStoreData, setAuthentication]);

  /**
   * @public
   *
   * login functions
   */
  const login = useCallback(
    async (
      email: string,
      password: string,
    ): Promise<void | [User, TDecodedToken]> => {
      await signInWithEmailAndPassword(auth, email, password);
      const userEntity = auth.currentUser;

      // decode token
      const tokenResult =
        (await auth.currentUser?.getIdTokenResult()) as TDecodedToken;

      if (userEntity && userHasPermission(READ.bitFlag, tokenResult)) {
        cleanStoreData();
        await setUserAuthentication(userEntity, tokenResult);
        return [userEntity, tokenResult];
      }
      return;
    },
    [cleanStoreData, setUserAuthentication, userHasPermission],
  );

  useEffect(() => {
    // this isn't just a subscription to an Observable. It emits the currentUser when this listener is attached
    // like a behavior subject, therefore, theres no change to have any race condition between firebase's internal
    // auth check, and adding this listener.
    // - this callback is called after the auth().signInXX method. This means theres a potential for a race
    //  condition. To avoid this create a login method that duplicates the same necessary authentication process.
    // - this callback is called after the auth().signInXX method. This means theres a potential for a race
    //  condition. To avoid this create a login method that duplicates the same necessary authentication process.
    const authUnsubscribe = auth.onAuthStateChanged(
      async function (userEntity) {
        try {
          if (!userEntity) {
            // decode access token
            setAuthentication(false);
            return;
          }

          const tokenResult =
            (await auth.currentUser?.getIdTokenResult()) as TDecodedToken;

          if (
            tokenResult.claims.perm._p === undefined ||
            (tokenResult.claims.perm._p & READ.bitFlag) !== READ.bitFlag
          ) {
            logout();
            return;
          }

          await setUserAuthentication(userEntity, tokenResult);
          setAuthentication(true);
        } catch (err) {
          /** */
        }
      },
    );

    return authUnsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if tokenExpiresAt is negative ignore refresh
    if (tokenExpiresAt < 0) {
      return;
    }

    // ms of time before token expiration
    const expiresIn = tokenExpiresAt - new Date().getTime();

    const timePadding = 10 * 1000; // 10 sec

    const refreshAuthTokenJob = setTimeout(async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const token = await currentUser.getIdTokenResult(true);
        setUserAuthentication(currentUser, token as TDecodedToken);
      }
    }, expiresIn - timePadding);

    return () => {
      clearTimeout(refreshAuthTokenJob);
    };
  }, [setUserAuthentication, tokenExpiresAt]);

  /*
   * Notes: Is the use of useMemo necessary here?
   * IMO yes. Without the memoizing the value passed into the provider an unintentional re-render can be
   * triggered without any value within the object having been changed; as a result, any child component would
   * be re-render. This is because defining the an object within a Components property will create a new
   * version of that object no matter the change (or not) of its content. Possible unintentional triggers
   * would be a parent component re-rendering or an internal method / object (async call?) updating.
   *
   * Its unlikely that a parent to this component will re-render and its internals are simple enough to
   * guarantee they wont cause a re-render, but we add it here to set a standard for other Contexts
   */
  const providerState = useMemo(
    () => ({
      isAuthenticated,
      initialized,
      reload,
      logout,
      login,
      hasPermission: userHasPermission,
      acceptedTC: authToken?.claims?.tc,
      user,
      uid: user?.uid,
      authToken,
    }),
    [
      isAuthenticated,
      initialized,
      reload,
      logout,
      login,
      userHasPermission,
      authToken,
      user,
    ],
  );

  return (
    <AuthenticationContext.Provider value={providerState}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): AuthenticationContextType => {
  return useContext(AuthenticationContext);
};
